export const mvSlider = new Swiper('.js-mvSlider.swiper', {
  effect: 'fade',
  fadeEffect: {
    crossFade: true,
  },
  centeredSlides: true,
  loop: true,
  loopAdditionalSlides: 1,
  speed: 1000,
  // slidesPerView: 1.5,
  // spaceBetween: 100,
  autoplay: {
    delay: 5000,
  },
  // pagination: {
  // 	el: '.js-mvSlider__pagination',
  // 	type: 'bullets',
  // 	clickable: true,
  // },
  on: {
    resize: function () {
      mvSlider.autoplay.start();
    },
  },
});
