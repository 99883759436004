// changeHeaderColor.js
export function changeHeaderColor() {
  // スクロールイベントを監視
  window.addEventListener('scroll', function () {
    // js-toggle-primaryクラスを持つすべての要素を検索
    let jsTogglePrimaryElements =
      document.querySelectorAll('.js-toggle-primary');
    // js-hamburgerクラスを持つ要素を検索
    let hamburgerElement = document.querySelector('.js-hamburger');

    // ビューポートの上端がjs-toggle-primary要素内にあるかどうかを判定するフラグ
    let isInViewport = false;

    jsTogglePrimaryElements.forEach(function (element) {
      let bounding = element.getBoundingClientRect();
      // 要素の上端がビューポート内にあるか、または要素の下端がビューポートの上端よりも下にあるかチェック
      if (bounding.top <= 0 && bounding.bottom >= 0) {
        isInViewport = true;
      }
    });

    // 条件に応じてクラスを追加または削除
    if (isInViewport && hamburgerElement) {
      hamburgerElement.classList.add('_primary');
    } else if (hamburgerElement) {
      hamburgerElement.classList.remove('_primary');
    }
  });
}
